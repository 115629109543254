import {Component} from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from 'app/core/user.service';
import { CmsNotificationsService } from '../core/notifications.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'cms-login',
    templateUrl: 'login.component.html'
})

export class CmsLoginComponent {

    formValid = false;

    loginForm: FormGroup;

    constructor(
        private router: Router,
        private userService: UserService,
        private notificationsService: CmsNotificationsService,
        private fb: FormBuilder
    ) {

        this.loginForm = this.fb.group({
            '_username': [undefined, Validators.required],
            '_password': [undefined, Validators.required]
        });
    }

    onSubmit (e) {
        e.preventDefault();

        if (!this.loginForm.invalid) {
            this.userService
                .login(this.loginForm.value)
                .subscribe(
                    () => {
                        // check did user requested any url before login
                        let nextUrl = localStorage.getItem('cms.requested-url');

                        if (nextUrl === null) {
                            nextUrl = '/dashboard';
                        } else {
                            localStorage.removeItem('cms.requested-url');
                        }

                        this.router.navigateByUrl(nextUrl);
                    },
                    (error) =>  {
                        this.notificationsService.show({
                            type: 'error',
                            content: error
                        });
                    }
                );
        } else {
            // form is invalid but user does not see errors because inputs
            // are not touched. Mark 'em as touched
            this.loginForm.controls['_username'].markAsTouched();
            this.loginForm.controls['_password'].markAsTouched();
        }

    }

}
