
import {throttleTime, debounceTime} from 'rxjs/operators';
import {Component, HostBinding, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CmsResources, Resource } from '../shared/resources.service';
import { CmsNotifyService } from '../../core/notify.service';
import { CmsNotificationsService } from '../../core/notifications.service';
import {Subject} from 'rxjs';

import {clone, isEqual} from 'lodash';
import {CmsTranslateService} from '../../shared/translate.service';
import { CmsModalService } from '../../core/modal.service';
import { CmsDeleteResourceComponent } from '../delete-resource/delete-resource.component';
import { UserService } from '../../core/user.service';

@Component({
    selector: 'cms-resources-edit',
    templateUrl: 'resources-edit.component.html'
})

export class CmsResourcesEditComponent implements OnInit {
    @HostBinding('class') classes = 'popup-overlay';
    public selectedResource: Resource;
    public editResourceForm: FormGroup;
    public $submit = new Subject<Event>();
    fieldsChanged = false;
    initialValues;
    titleValidationInterpolationParams;

    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        private notifyService: CmsNotifyService,
        private resourcesService: CmsResources,
        private formBuilder: FormBuilder,
        private notificationsService: CmsNotificationsService,
        private translateService: CmsTranslateService,
        private modalService: CmsModalService,
        private userService: UserService
    ) {
        this.setSubmitListener();

        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '80'
        };

        this.editResourceForm = formBuilder.group({
            name: [{value: '', disabled: true}],
            title: ['', [Validators.required, Validators.maxLength(80)]]
        });
    }

    ngOnInit() {
        this.activeRoute.params.subscribe((params: {id: string}) => {
            this.resourcesService.getResource(params.id , {extend : 'container', locale: this.userService.data.user.locale}).subscribe(
                (response: {resource: Resource}) => {
                    this.selectedResource = response.resource;
                    this.editResourceForm.patchValue({
                        title: this.selectedResource.title,
                        name: this.selectedResource.name,
                    });

                    this.initialValues = clone(this.editResourceForm.value);
                },
                (err: any) => {
                    this.notificationsService.show({
                        type: 'error',
                        content: err.message
                    });
                }
            );
        });

        this.notifyService.change$.subscribe((itemObj: object) => {
            if (itemObj['type'] === 'resources.closeDeleteModal') {
                this.closePopup();
            }
        });

        this.editResourceForm.valueChanges.pipe(
            debounceTime(200))
            .subscribe((resource: any) => {
                this.fieldsChanged = !isEqual(resource, this.initialValues);
            });
    }

    ngOnDestroy(){
        this.modalService.close('delete-resource');
    }

    public getFileType (type?: string): string {
        if (!type) {
            return '';
        }

        const imageType = /image.*/;
        const videoType = /video.*/;
        const isImage = type.match(imageType);
        const isVideo = type.match(videoType);

        if (isImage) {
            return 'image';
        } else if (isVideo) {
            return 'video';
        } else {
            return 'file';
        }
    }

    public getImgUrl(resource) {
        if (!resource) {
            return '';
        }
        let url = resource['thumb-large-url'] || resource.url;
        url = url.replace('(', '%28').replace(')', '%29');
        return url;
    }

    public deleteAction(resource): void {
        this.modalService.open({
            id: 'delete-resource',
            component: CmsDeleteResourceComponent,
            data: {
                resource: resource
            }
        });
    }

    onSubmit({value}) {
        this.resourcesService.updateResource(this.selectedResource.id, value.title).subscribe(
            () => {
                this.notificationsService.show({
                    type: 'action',
                    content: this.translateService.translate('resources_file_update_success')
                });
                this.notifyService.notify({type: 'resources'});
                this.closePopup();
            },
            (err) => {
                this.notificationsService.show({
                    type: 'error',
                    content: err.message
                });
            }
        );
    }

    public closePopup() {
        this.router.navigate([{outlets: {popup: null}}], {queryParamsHandling: 'preserve'});
        this.notifyService.notify({type: 'resources.closeEditModal'});
    }

    private setSubmitListener() {
        this.$submit.pipe(
            throttleTime(500))
            .subscribe(() => {
                this.onSubmit(this.editResourceForm);
            });
    }
}
