import { forkJoin } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { CmsArticlesService } from '../shared/articles.service';
import { CmsCommonService, NameValidationPattern } from '../../shared/common.service';
import { CmsTranslateService } from '../../shared/translate.service';
import { CmsNotifyService } from '../../core/notify.service';
import { CmsNotificationsService } from 'app/core/notifications.service';
import { clone, isEqual, sortBy } from 'lodash';
import { UserService } from '../../core/user.service';
var CmsEditCategoryComponent = /** @class */ (function () {
    function CmsEditCategoryComponent(activeRoute, articlesService, commonService, fb, notificationsService, notifyService, router, translateService, userService) {
        var _this = this;
        this.activeRoute = activeRoute;
        this.articlesService = articlesService;
        this.commonService = commonService;
        this.fb = fb;
        this.notificationsService = notificationsService;
        this.notifyService = notifyService;
        this.router = router;
        this.translateService = translateService;
        this.userService = userService;
        this.classes = 'popup-overlay';
        this.categories = [];
        this.fieldsChanged = false;
        this.canEditGlobalCategories = userService.data.modules['articles']['specialPermissions'].canEditGlobalArticles;
        this.nameValidationInterpolationParams = {
            field: this.translateService.translate('common_name').toLowerCase(),
            maximum: '40',
            characters: NameValidationPattern.description
        };
        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '80'
        };
        this.editCategory = this.fb.group({
            name: ['', [Validators.required, Validators.maxLength(40), Validators.pattern(NameValidationPattern.pattern)]],
            title: ['', [Validators.required, Validators.maxLength(80)]],
            active: false,
            locale: [
                {
                    value: '',
                    disabled: true
                },
                [Validators.required]
            ],
            parent: '',
            company: '',
            app: ''
        });
        this.activeRoute.params.subscribe(function (params) {
            forkJoin([
                _this.commonService.getLocales(),
                _this.articlesService.getArticleCategory(params.id, { locale: params.localeId }),
            ]).subscribe(function (_a) {
                var localeData = _a[0], categoryData = _a[1];
                _this.articlesService
                    .getArticleCategories({ company: categoryData.category.company, app: categoryData.category.app, pageSize: 1000 })
                    .subscribe(function (categoriesData) {
                    _this.categories = sortBy(categoriesData.categories.filter(function (category) {
                        // Leave all categories except the one we're editing
                        return category.id !== Number(params.id);
                    }), 'title');
                    var selectedParentCategory = _this.categories.find(function (category) {
                        return category.id === categoryData.category.parent;
                    });
                    _this.editCategory.patchValue({
                        parent: selectedParentCategory
                    });
                    _this.initialValues = clone(_this.editCategory.value);
                });
                _this.locales = localeData.locales;
                var selectedLocale = _this.locales.find(function (locale) {
                    return locale.id === categoryData.category.locale;
                });
                _this.editCategory.patchValue({
                    name: categoryData.category.name,
                    title: categoryData.category.title,
                    active: categoryData.category.active,
                    locale: selectedLocale,
                    company: categoryData.category.company,
                    app: categoryData.category.app
                });
                _this.initialValues = clone(_this.editCategory.value);
            });
        });
        this.editCategory.valueChanges.pipe(debounceTime(200))
            .subscribe(function (category) {
            _this.fieldsChanged = !isEqual(category, _this.initialValues);
        });
    }
    CmsEditCategoryComponent.prototype.onSubmit = function () {
        var _this = this;
        // getRawValue is used when there are disabled fields in form
        this.articlesService.editArticleCategory(this.editCategory.getRawValue(), this.activeRoute.snapshot.params['id'])
            .subscribe(function () {
            _this.notificationsService.show({
                type: 'action',
                content: 'Category edited'
            });
            _this.notifyService.notify('category');
            _this.closePopup();
        }, function (error) {
            _this.notificationsService.show({
                type: 'error',
                content: error
            });
        });
    };
    CmsEditCategoryComponent.prototype.closePopup = function () {
        this.router.navigate([{ outlets: { popup: null } }], { queryParamsHandling: 'preserve' });
    };
    return CmsEditCategoryComponent;
}());
export { CmsEditCategoryComponent };
