
import {Observable, Subject, forkJoin} from 'rxjs';
import {throttleTime, debounceTime} from 'rxjs/operators';
import {Component, HostBinding, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {
    Article, ArticleProvider, ArticleStatus, CategoriesResponse, Category,
    CmsArticlesService
} from '../shared/articles.service';
import {CmsCommonService, NameValidationPattern} from '../../shared/common.service';
import {CmsNotifyService} from '../../core/notify.service';
import {clone, cloneDeep, isEqual, sortBy} from 'lodash';

import {CmsNotificationsService} from 'app/core/notifications.service';
import {CmsTranslateService} from '../../shared/translate.service';

@Component({
    selector: 'cms-edit-article',
    templateUrl: 'edit-article.component.html'
})

export class CmsEditArticleComponent implements OnInit {
    @HostBinding('class') classes = 'popup-overlay';
    article: FormGroup;
    locales: Array<Object>;
    categories: Array<Category>;
    providers: Array<ArticleProvider>;
    statuses: Array<ArticleStatus>;
    $submit = new Subject<Event>();
    fieldsChanged = false;
    initialValues;
    nameValidationInterpolationParams;
    titleValidationInterpolationParams;
    summaryValidationInterpolationParams;

    constructor(
        private fb: FormBuilder,
        private articlesService: CmsArticlesService,
        private commonService: CmsCommonService,
        private activeRoute: ActivatedRoute,
        private router: Router,
        private notificationsService: CmsNotificationsService,
        private notifyService: CmsNotifyService,
        private translateService: CmsTranslateService
    ) {
        this.setSubmitListener();
        this.nameValidationInterpolationParams = {
            field: this.translateService.translate('common_name').toLowerCase(),
            maximum: '120',
            characters: NameValidationPattern.description
        };

        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '120'
        };

        this.summaryValidationInterpolationParams = {
            field: this.translateService.translate('articles_article_summary').toLowerCase(),
            maximum: '400'
        };
    }

    ngOnInit() {
        this.article = this.fb.group({
            name: ['', [Validators.required, Validators.maxLength(120), Validators.pattern(NameValidationPattern.pattern)]],
            title: ['', [Validators.required, Validators.maxLength(120)]],
            status: ['', [Validators.required]],
            category: ['', [Validators.required]],
            provider: ['', [Validators.required]],
            locale: [
                {
                    value: '',
                    disabled: true
                },
                [Validators.required]
            ],
            company: '',
            summary: ['', [Validators.maxLength(400)]],
            content: [''],
            featuredImage: ''
        });

        this.article.valueChanges.pipe(
            debounceTime(200))
            .subscribe((article: any) => {
                this.fieldsChanged = !isEqual(article, this.initialValues);
            });

        this.activeRoute.params.subscribe((params: {id: string}) => {
            forkJoin([
                this.commonService.getLocales(),
                this.articlesService.getArticleProviders(),
                this.articlesService.getArticle(params['id'], {locale: params['localeId']}),
                this.articlesService.getArticleStatuses()
            ]).subscribe(([
                localesData,
                providersData,
                articleData,
                statusesData
            ]) => {
                const categoryParams: any = {
                    locale: articleData.article.locale,
                    pageSize: 1000
                };

                if (articleData.article.app) {
                    categoryParams.app = articleData.article.app;
                } else if (articleData.article.company) {
                    categoryParams.company = articleData.article.company;
                }

                this.articlesService.getArticleCategories(categoryParams).subscribe((categoriesData: CategoriesResponse) => {
                    this.categories = sortBy(categoriesData['categories'], 'title');

                    const selectedCategory = this.categories.find((category: {id: number}) => {
                        return category.id === articleData['article']['category'];
                    });

                    this.article.patchValue({
                        category: selectedCategory
                    });

                    this.initialValues = clone(this.article.value);
                });

                this.locales = localesData['locales'];
                this.providers = providersData['providers'];
                this.statuses = statusesData.articleStatuses;

                const selectedLocale = this.locales.find((locale: {id: number}) => {
                    return locale.id === articleData['article']['locale'];
                });

                const selectedProvider = this.providers.find((provider: {id: number}) => {
                    return provider.id === articleData['article']['provider'];
                });

                const selectedStatus = this.statuses.find((status: ArticleStatus) => {
                    return status.id === articleData.article.status;
                });

                this.article.patchValue({
                    name: articleData['article']['name'],
                    title: articleData['article']['title'],
                    status: selectedStatus,
                    locale: selectedLocale,
                    provider: selectedProvider,
                    summary: articleData['article']['summary'] || '',
                    content: articleData['article']['content'] || '',
                    featuredImage: articleData['article']['featuredImage']
                });

                this.initialValues = clone(this.article.value);
            },
                this.showErrorNotification
            );
        });
    }

    onSubmit() {
        // getRawValue is used when there are disabled fields in form
        const value = this.article.getRawValue();
        const article: Article = cloneDeep(value);

        article.locale = value.locale.id;
        article.provider = value.provider.id;
        article.category = value.category.id;
        article.status = value.status.id;

        this.articlesService.editArticle(
            article,
            this.activeRoute.snapshot.params['id']
        ).subscribe(() => {
            this.notificationsService.show({
                type: 'action',
                content: this.translateService.translate('articles_article_edited')
            });
            this.notifyService.notify('article');
            this.closePopup();
        },
            this.showErrorNotification
        );
    }

    closePopup() {
        this.router.navigate([{outlets: {popup: null}}], {queryParamsHandling: 'preserve'});
    }

    private setSubmitListener() {
        this.$submit.pipe(
            throttleTime(500))
            .subscribe(() => {
                this.onSubmit();
            });
    }

    private showErrorNotification = error => {
        this.notificationsService.show({
            type: 'error',
            content: error
        });
    }
}
