
import {throttleTime} from 'rxjs/operators';
import {Component, HostBinding, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {CmsNotificationsService} from 'app/core/notifications.service';
import {cloneDeep, extend, sortBy, pick} from 'lodash';

import {Subject} from 'rxjs';
import {App, CmsAppsService} from '../../apps/shared/apps.service';
import {CmsNotifyService} from '../../core/notify.service';
import {UserService} from '../../core/user.service';
import {CmsCommonService, Company, Locale, LocalesResponse, NameValidationPattern} from '../../shared/common.service';
import {CmsTranslateService} from '../../shared/translate.service';
import {
    Article,
    ArticleProvider,
    ArticleStatus,
    ArticleStatusesResponse,
    CategoriesResponse,
    Category,
    CATEGORY_TYPE,
    CategoryType,
    CmsArticlesService,
    ProvidersResponse
} from '../shared/articles.service';

@Component({
    selector: 'cms-new-article',
    templateUrl: 'new-article.component.html'
})

export class CmsNewArticleComponent implements OnInit {
    @HostBinding('class') classes = 'popup-overlay';
    applications: App[];
    canCreateGlobalArticle: boolean;
    categoryType = CATEGORY_TYPE;
    categoryTypes = [{
        id: CATEGORY_TYPE.company,
        title: this.translateService.translate('common_company')
    }, {
        id: CATEGORY_TYPE.application,
        title: this.translateService.translate('applications_application')
    }];
    newArticle: FormGroup;
    locales: Array<Object>;
    categories: Array<Category> = [];
    providers: Array<ArticleProvider>;
    companies: Array<Company>;
    statuses: Array<ArticleStatus>;
    $submit = new Subject<Event>();
    nameValidationInterpolationParams;
    titleValidationInterpolationParams;
    summaryValidationInterpolationParams;
    private savedApplications;
    private categoryQueryParams = {
        pageSize: 1000,
        company: null,
        locale: null,
        app: null,
        showInactive: 0
    };

    constructor(
        private fb: FormBuilder,
        private appService: CmsAppsService,
        private articlesService: CmsArticlesService,
        private commonService: CmsCommonService,
        private router: Router,
        private notificationsService: CmsNotificationsService,
        private notifyService: CmsNotifyService,
        private userService: UserService,
        private translateService: CmsTranslateService
    ) {
        this.canCreateGlobalArticle = this.userService.data.modules['articles']['specialPermissions'].canEditGlobalArticles;

        this.nameValidationInterpolationParams = {
            field: this.translateService.translate('common_name').toLowerCase(),
            maximum: '120',
            characters: NameValidationPattern.description
        };

        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '120'
        };

        this.summaryValidationInterpolationParams = {
            field: this.translateService.translate('articles_article_summary').toLowerCase(),
            maximum: '400'
        };

        this.setSubmitListener();
    }

    ngOnInit() {
        this.newArticle = this.fb.group({
            type: ['', [Validators.required]],
            name: ['', [Validators.required, Validators.maxLength(120), Validators.pattern(NameValidationPattern.pattern)]],
            title: ['', [Validators.required, Validators.maxLength(120)]],
            category: ['', [Validators.required]],
            provider: ['', [Validators.required]],
            locale: ['', [Validators.required]],
            status: ['', [Validators.required]],
            company: '',
            application: '',
            summary: ['', [Validators.maxLength(400)]],
            content: '',
            featuredImage: ''
        });
        this.companies = sortBy(this.userService.data.user.companies, 'title');

        this.loadData();
        this.setDropdownListeners();
    }

    onSubmit({value}) {
        const article: Article = pick(value, ['name', 'title', 'summary', 'content', 'featuredImage']);

        article.locale = value.locale.id;
        article.provider = value.provider.id;
        article.category = value.category.id;
        article.status = value.status.id;

        this.articlesService.createArticle(article).subscribe(() => {
            this.notificationsService.show({
                type: 'action',
                content: 'Article created'
            });
            this.notifyService.notify('article');
            this.closePopup();
        }, (error) => {
            this.notificationsService.show({
                type: 'error',
                content: error
            });
        });
    }

    closePopup() {
        this.router.navigate([{outlets: {popup: null}}], {queryParamsHandling: 'preserve'});
    }

    private setDropdownListeners() {
        this.newArticle.controls['company'].valueChanges.subscribe((company: Company) => {
            if (company) {
                delete this.categoryQueryParams.app;
                extend(this.categoryQueryParams, {company: company.id});
            } else {
                delete this.categoryQueryParams.company;
            }

            this.loadCategories(this.categoryQueryParams);
        });

        this.newArticle.controls['locale'].valueChanges.subscribe((locale: Locale) => {
            if (locale) {
                extend(this.categoryQueryParams, {locale: locale.id});
            } else {
                delete this.categoryQueryParams.locale;
            }

            this.loadCategories(this.categoryQueryParams);
        });

        this.newArticle.controls['application'].valueChanges.subscribe((application: App) => {
            if (application) {
                delete this.categoryQueryParams.company;
                extend(this.categoryQueryParams, {app: application.id});
            } else {
                delete this.categoryQueryParams.app;
            }

            this.loadCategories(this.categoryQueryParams);
        });

        this.newArticle.controls['type'].valueChanges.subscribe((type: CategoryType) => {
            const values = {
                application: null,
                company: null
            };
            this.newArticle.controls[type.id].setValidators([Validators.required]);

            switch (type.id) {
                case this.categoryType.application:
                    this.newArticle.get('company').clearValidators();
                    if (this.applications &&
                        this.applications.length === 1 &&
                        !this.newArticle.get('application').value
                    ) {
                        extend(this.categoryQueryParams, {applications: this.applications[0].id});
                        values.application = this.applications[0];
                        this.loadCategories(this.categoryQueryParams);
                    }
                    this.newArticle.patchValue(values, {emitEvent: false});
                    break;
                case this.categoryType.company:
                    this.newArticle.get('application').clearValidators();
                    if (this.companies &&
                        this.companies.length === 1 &&
                        !this.newArticle.get('company').value
                    ) {
                        extend(this.categoryQueryParams, {company: this.companies[0].id});
                        values.company = this.companies[0];
                        this.loadCategories(this.categoryQueryParams);
                    }
                    this.newArticle.patchValue(values, {emitEvent: false});
                    break;
            }
        });
    }

    private setSubmitListener() {
        this.$submit.pipe(
            throttleTime(500))
            .subscribe(() => {
                this.onSubmit(this.newArticle);
            });
    }

    private clearCategories(categories: Array<Category>) {
        const selectedCategory = this.newArticle.controls['category'].value;

        if (selectedCategory) {
            const selectedInCategories = categories.some((category: Category) => {
                return category.id === selectedCategory.id;
            });

            if (!selectedInCategories) {
                this.newArticle.patchValue({category: null});
            }
        } else {
            this.newArticle.patchValue({category: null});
        }
    }

    private loadCategories(params: any) {
        this.articlesService.getArticleCategories(params).subscribe((categoriesData: CategoriesResponse) => {
            this.categories = categoriesData.categories;
            this.clearCategories(this.categories);
        });
    }

    private loadData() {
        this.loadApplications();
        this.loadLocales();
        this.loadProviders();
        this.loadStatuses();
    }

    private loadApplications(): void {
        this.appService.getAllApps().subscribe(applicationsData => {
            const applications = this.canCreateGlobalArticle ?
                applicationsData.apps :
                applicationsData.apps.filter(application => (application.company !== null));
            this.applications = sortBy(applications, 'name');
            this.savedApplications = applicationsData.apps;
        })
    }

    private loadLocales() {
        this.commonService.getLocales().subscribe((data: LocalesResponse) => {
            this.locales = sortBy(data.locales, 'title');
        });
    }

    private loadProviders() {
        this.articlesService.getArticleProviders().subscribe((data: ProvidersResponse) => {
            this.providers = sortBy(data.providers, 'title');

            // Populate provider with Seven provider if it exists to quicken article creation
            const defaultProvider = data.providers.find((provider: ArticleProvider) => {
                return provider.name === 'seven';
            });

            if (defaultProvider) {
                this.newArticle.patchValue({provider: defaultProvider});
            }
        });
    }

    private loadStatuses() {
        this.articlesService.getArticleStatuses().subscribe((data: ArticleStatusesResponse) => {
            this.statuses = data.articleStatuses;
        });
    }
}
