import {Component, ViewChild, OnInit, HostBinding} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CmsAppTemplateSettingsService, SETTINGS_TYPES} from '../shared/app-template-settings.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CmsNotificationsService} from '../../core/notifications.service';
import {CmsNotifyService} from 'app/core/notify.service';
import {CmsTranslateService} from '../../shared/translate.service';
import {JsonEditorComponent} from '../../shared/json-editor/json-editor.component';
import {isEqual, cloneDeep} from 'lodash';
import {validateJson} from 'app/shared/json-editor/json-validator.directive';
import {debounceTime} from 'rxjs/operators';

@Component({
    selector: 'cms-edit-app-template-setting',
    templateUrl: 'edit-app-template-setting.component.html',
    providers: [CmsAppTemplateSettingsService]
})
export class CmsAppTemplateSettingsEditComponent implements OnInit {
    @HostBinding('class') classes = 'popup-overlay';
    form: FormGroup;
    settingTypes = SETTINGS_TYPES;
    originalData: any;
    valueChanged: boolean = false;
    private valueValidators = [Validators.required];

    @ViewChild(JsonEditorComponent, {static: false}) editor: JsonEditorComponent;

    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        private appTemplateSettingsService: CmsAppTemplateSettingsService,
        private fb: FormBuilder,
        private notificationsService: CmsNotificationsService,
        private notifyService: CmsNotifyService,
        private translateService: CmsTranslateService) {
        this.form = this.fb.group({
            appTemplate: this.fb.group(
                {
                    title: [
                        {
                            value: '',
                            disabled: true
                        }
                    ]
                }
            ),
            key: [
                {
                    value: '',
                    disabled: true
                },
                [Validators.required]
            ],
            value: ['', this.valueValidators],
            type: [{id: ''}, [Validators.required]]
        });

        this.activeRoute.params.subscribe((params: {settingId: string, key: string}) => {
            this.appTemplateSettingsService.getAppTemplateSetting({
                settingId: params.settingId,
                key: params.key,
                search: {extend: 'appTemplate'}
            }).subscribe(res => {
                this.originalData = cloneDeep(res.appTemplateSetting);
                this.setValueValidators(this.originalData.type);
                res.appTemplateSetting.type = this.settingTypes.find(type => {
                    return this.originalData.type === type.id;
                });
                this.form.patchValue(res.appTemplateSetting);
            });
        });
    }

    setValueValidators(type) {
        if (type === 'object'
            || type === 'config') {
            this.form.controls['value'].setValidators(
                Validators.compose([...this.valueValidators, validateJson])
            );
        } else  {
            this.form.controls['value'].setValidators(this.valueValidators);
        }
    }

    ngOnInit() {
        this.form.valueChanges.pipe(
            debounceTime(200))
            .subscribe((setting: any) => {
                this.valueChanged = !(isEqual((setting.type && setting.type.id), this.originalData.type) &&
                    isEqual(setting.value, this.originalData.value));
            });
    }

    onSelectChanged(value: any) {
        if (value) {
            this.setValueValidators(value.id);
        }

        this.form.controls['value'].markAsTouched();
        this.form.controls['value'].markAsDirty({onlySelf: true});
        this.form.controls['value'].updateValueAndValidity();
    }

    onSubmit(form: FormGroup) {

        this.appTemplateSettingsService.updateAppTemplateSetting({
            settingId: this.activeRoute.snapshot.params['settingId'],
            value: form.value.value,
            type: form.value.type.id
        }).subscribe(
            res => {
                this.notificationsService.show({
                    type: 'action',
                    content: this.translateService.translate('settings_update_setting_success')
                });
                this.closePopup().then( () => {
                    this.notifyService.notify('appTemplateSettingEdit');
                });
            },
            error => {
                this.notificationsService.show({
                    type: 'error',
                    content: error.message
                });
            }
        );
    }

    closePopup() {
        return this.router.navigate(
            [{outlets: {popup: null}}],
            {queryParamsHandling: 'merge'}
        );
    }
}
