import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CmsCommonService, NameValidationPattern } from '../../shared/common.service';
import { CmsResources } from '../shared/resources.service';
import { CmsNotificationsService } from '../../core/notifications.service';
import { CmsNotifyService } from '../../core/notify.service';
import {CmsTranslateService} from '../../shared/translate.service';

@Component({
    selector: 'cms-new-container',
    templateUrl: 'new-container.component.html'
})

export class CmsNewContainerComponent implements OnInit {
    public newContainer: FormGroup;
    public companies: Array<Object>;
    nameValidationInterpolationParams;
    titleValidationInterpolationParams;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private commonService: CmsCommonService,
        private resourcesService: CmsResources,
        private notifyService: CmsNotifyService,
        private notificationsService: CmsNotificationsService,
        private translateService: CmsTranslateService

    ) {
        this.nameValidationInterpolationParams = {
            field: this.translateService.translate('common_name').toLowerCase(),
            maximum: '80',
            characters: NameValidationPattern.description
        };
        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '80'
        };
    }

    ngOnInit() {
        this.newContainer = this.fb.group({
            name: ['', [Validators.required, Validators.maxLength(80), Validators.pattern(NameValidationPattern.pattern)]],
            title: ['', [Validators.required, Validators.maxLength(80)]],
            company: ['', [Validators.required]],
            createOptimizedImages: false,
        });

        this.commonService.getCompanies({pageSize: 1000}).subscribe((response) => {
           this.companies = response.companies;
        });
    }

    public closePopup() {
        this.router.navigate([{outlets: {popup: null}}], {queryParamsHandling: 'preserve'});
    }

    public onSubmit({value}) {
        const data = {
            name : value.name,
            title : value.title,
            company : value.company.id,
            createOptimizedImages: value.createOptimizedImages,
        };

        this.resourcesService.createContainer(data).subscribe(
            () => {
                this.notifyService.notify({type: 'containers'});
                this.closePopup();
            },
            (err) => {
                this.notificationsService.show({
                    type: 'error',
                    content: err.message
                });
            }
        );
    }
}
