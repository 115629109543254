
import {Observable , Subject, forkJoin} from 'rxjs';
import {throttleTime, debounceTime} from 'rxjs/operators';
import {Component, HostBinding, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CmsAppsService } from '../shared/apps.service';
import { CmsCommonService, NameValidationPattern } from '../../shared/common.service';
import { CmsNotifyService } from '../../core/notify.service';
import {clone, cloneDeep, isEqual, sortBy} from 'lodash';

import {CmsNotificationsService} from '../../core/notifications.service';
import {CmsTranslateService} from '../../shared/translate.service';

@Component({
    selector: 'cms-edit-app',
    templateUrl: 'edit-app.component.html'
})

export class CmsEditAppComponent implements OnInit {
    @HostBinding('class') classes = 'popup-overlay';
    app: FormGroup;
    companies: Array<Object>;
    appTemplates: Array<Object> = [];
    $submit = new Subject<Event>();
    fieldsChanged = false;
    initialValues;
    nameValidationInterpolationParams;
    titleValidationInterpolationParams;

    constructor(
        private fb: FormBuilder,
        private appsService: CmsAppsService,
        private commonService: CmsCommonService,
        private activeRoute: ActivatedRoute,
        private router: Router,
        private notificationsService: CmsNotificationsService,
        private notifyService: CmsNotifyService,
        private translateService: CmsTranslateService
    ) {
        this.setSubmitListener();
        this.nameValidationInterpolationParams = {
            field: this.translateService.translate('common_name').toLowerCase(),
            maximum: '80',
            characters: NameValidationPattern.description
        };
        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '80'
        };
    }

    ngOnInit() {
        this.app = this.fb.group({
            name: ['', [Validators.required, Validators.maxLength(80), Validators.pattern(NameValidationPattern.pattern)]],
            title: ['', [Validators.required, Validators.maxLength(80)]],
            appTemplate: ['', [Validators.required]],
            company: ['', [Validators.required]],
            active: false
        });

        this.activeRoute.params.subscribe((params: {id: number}) => {
            forkJoin([
                this.appsService.getApp(params['id'], { extend: 'company,appTemplate' }),
                this.commonService.getCompanies({pageSize: 1000}),
                this.appsService.getAppsTemplates({pageSize: 1000})
            ]).subscribe(([
                appData,
                companyData,
                appTemplateData
            ]) => {
                this.companies = sortBy(companyData.companies, 'title');
                this.appTemplates = sortBy(appTemplateData.appTemplates, 'title');

                const selectedCompany = this.companies.find((locale: {id: number}) => {
                    return locale.id === appData.app.company.id;
                });

                const selectedAppTemplate = this.appTemplates.find((provider: {id: number}) => {
                    return provider.id === appData.app.appTemplate.id;
                });

                this.app.patchValue({
                    name: appData.app.name,
                    title: appData.app.title,
                    appTemplate: selectedAppTemplate,
                    company: selectedCompany,
                    active: appData.app.active
                });

                this.initialValues = clone(this.app.value);
            });
        });

        this.app.valueChanges.pipe(
            debounceTime(200))
            .subscribe((app: any) => {
                this.fieldsChanged = !isEqual(app, this.initialValues);
            });
    }

    onSubmit({value}) {
        const app = cloneDeep(value);

        app.company = value.company.id;
        app.appTemplate = value.appTemplate.id;

        this.appsService.editApp(
            app,
            this.activeRoute.snapshot.params['id']
        ).subscribe(() => {
            this.notificationsService.show({
                type: 'action',
                content: this.translateService.translate('applications_application_edited')
            });
            this.closePopup().then( () => {
                this.notifyService.notify('app');
            });
        }, (error) => {
            this.notificationsService.show({
                type: 'error',
                content: error
            });
        });
    }

    closePopup() {
        return this.router.navigate([{outlets: {popup: null}}], {queryParamsHandling: 'preserve'});
    }

    private setSubmitListener() {
        this.$submit.pipe(
            throttleTime(500))
            .subscribe(() => {
                this.onSubmit(this.app);
            });
    }
}
