import { Component } from '@angular/core';

import { CmsModalService } from '../../core/modal.service';
import { CmsNotificationsService } from '../../core/notifications.service';
import { CmsNotifyService } from '../../core/notify.service';
import { CmsTranslateService } from '../../shared/translate.service';
import { CmsAppTemplateSettingsService } from '../shared/app-template-settings.service';

@Component({
    selector: 'cms-archive-template-setting',
    templateUrl: 'archive-template-setting.component.html',
    providers: [CmsAppTemplateSettingsService]
})
export class CmsArchiveTemplateSettingComponent {
    data: number;

    constructor(
        private modalService: CmsModalService,
        private notificationsService: CmsNotificationsService,
        private notifyService: CmsNotifyService,
        private translateService: CmsTranslateService,
        private appTemplateSettingsService: CmsAppTemplateSettingsService,
    ) {}

    archiveSetting() {
        this.appTemplateSettingsService.archiveTemplateSetting(this.data).subscribe(
            () => {
                this.notificationsService.show({
                    type: 'action',
                    content: this.translateService.translate('settings_setting_archive_success')
                });
                this.closePopup();
                this.notifyService.notify('appTemplateSettingArchive');
            },
            (err: any) => {
                this.notificationsService.show({
                    type: 'error',
                    content: err.message
                });
            }
        );
    }

    closePopup() {
        this.modalService.close('archive-template-setting');
    }
}
