import { forkJoin } from 'rxjs';
import { OnInit, OnDestroy } from '@angular/core';
import { CmsModalService } from '../../core/modal.service';
import { FormBuilder, Validators } from '@angular/forms';
import { CmsNotifyService } from '../../core/notify.service';
import { CmsTranslateService } from '../../shared/translate.service';
import { CmsCommonService } from '../../shared/common.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CmsResourcePickerModalComponent } from '../../shared/resource-picker/reference-picker-modal/resource-picker-modal.component';
import { find } from 'lodash';
import { CmsMarketingService } from '../shared/marketing.service';
import { CmsNotificationsService } from '../../core/notifications.service';
var CmsTranslateMarketingSlotItemComponent = /** @class */ (function () {
    function CmsTranslateMarketingSlotItemComponent(modalService, fb, notifyService, translateService, commonService, sanitizer, marketingService, notificationsService) {
        this.modalService = modalService;
        this.fb = fb;
        this.notifyService = notifyService;
        this.translateService = translateService;
        this.commonService = commonService;
        this.sanitizer = sanitizer;
        this.marketingService = marketingService;
        this.notificationsService = notificationsService;
    }
    CmsTranslateMarketingSlotItemComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form = this.fb.group({
            reference: ['', [Validators.required]]
        });
        this.trustedUrl = this.sanitizer.bypassSecurityTrustStyle('url("' +
            this['data']['item']['row']['resource']['url'] + '")');
        forkJoin([
            this.commonService.getLocales()
        ]).subscribe(function (_a) {
            var localeData = _a[0];
            _this.localeTo = find(localeData.locales, { 'id': _this['data']['item']['language']['id'] });
            _this.marketingService.getMarketingSlotItem(_this['data']['item']['row']['id'], _this.localeTo.id)
                .subscribe(function (res) {
                _this.form.get('reference').patchValue(res.marketingSlotItem.resource);
            });
        });
        this.resourcePickerSubscription = this.notifyService.change$.subscribe(function (item) {
            if (item.type === 'resource-picker-modal-pick') {
                _this.form.get('reference').patchValue(item.data);
            }
        });
    };
    CmsTranslateMarketingSlotItemComponent.prototype.ngOnDestroy = function () {
        this.resourcePickerSubscription.unsubscribe();
        this.modalService.close('resource-picker-modal');
    };
    CmsTranslateMarketingSlotItemComponent.prototype.translateItem = function () {
        var _this = this;
        this.marketingService.translateMarketingItemSlot({
            itemId: this['data']['item']['row']['id'],
            localeId: this.localeTo.id,
            reference: this.form.get('reference').value.id
        }).subscribe(function (res) {
            _this.notificationsService.show({
                type: 'action',
                content: _this.translateService.translate('resources_resource_translated')
            });
            _this.closePopup();
        }, function (error) {
            _this.notificationsService.show({
                type: 'error',
                content: error
            });
        });
    };
    CmsTranslateMarketingSlotItemComponent.prototype.openResourcePicker = function () {
        this.modalService.open({
            id: 'resource-picker-modal',
            component: CmsResourcePickerModalComponent,
            data: {
                options: {
                    mimeType: ['image/png', 'image/jpeg']
                }
            }
        });
    };
    CmsTranslateMarketingSlotItemComponent.prototype.getImgUrl = function (resource) {
        if (!resource) {
            return '';
        }
        return resource['thumb-large-url'] || resource.url;
    };
    CmsTranslateMarketingSlotItemComponent.prototype.closePopup = function () {
        this.modalService.close('translate-marketing-slot-item');
    };
    return CmsTranslateMarketingSlotItemComponent;
}());
export { CmsTranslateMarketingSlotItemComponent };
