
import {forkJoin} from 'rxjs';
import {Component, HostBinding, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CmsLayout, CmsPage, CmsPagesService, CmsPageTemplate, FIELDS_OVERRIDE_WHITELIST} from '../shared/pages.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CmsTranslateService} from '../../shared/translate.service';
import {App, CmsAppsService} from '../../apps/shared/apps.service';
import {CmsNotifyService} from '../../core/notify.service';
import {CmsNotificationsService} from '../../core/notifications.service';
import {filter, cloneDeep, isEqual} from 'lodash';
import {validateJson} from '../../shared/json-editor/json-validator.directive';
import {validateCheckboxRequired} from '../../shared/validators.service';
import {NameValidationPattern} from '../../shared/common.service';

@Component({
  selector: 'cms-edit-page',
  templateUrl: './edit-page.component.html',
  providers: [CmsPagesService]
})
export class CmsEditPageComponent implements OnInit {
    @HostBinding('class') classes = 'popup-overlay';
  form: FormGroup;
  layoutsData: Array<CmsLayout> = [];
  apps: Array<App> = [];
  linkedPageTemplate: CmsPageTemplate = null;
  nameValidationInterpolationParams;
  titleValidationInterpolationParams;
  subtitleValidationInterpolationParams;
  keywordsValidationInterpolationParams;
  customDataFieldChanged = false;
  layoutFieldChanged = false;
  pageTemplates: Array<CmsPageTemplate> = [];
  private titleValidators = [
    Validators.required, Validators.maxLength(80)
  ];

  constructor(private fb: FormBuilder,
              private activeRoute: ActivatedRoute,
              private router: Router,
              private pageService: CmsPagesService,
              private translateService: CmsTranslateService,
              private appService: CmsAppsService,
              private notifyService: CmsNotifyService,
              private notificationsService: CmsNotificationsService
  ) {
    this.nameValidationInterpolationParams = {
      field: this.translateService.translate('common_name').toLowerCase(),
      maximum: '40',
        characters: NameValidationPattern.description
    };

    this.titleValidationInterpolationParams = {
      field: this.translateService.translate('common_title').toLowerCase(),
      maximum: '80'
    };

    this.subtitleValidationInterpolationParams = {
      field: this.translateService.translate('common_subtitle').toLowerCase(),
      maximum: '120'
    };

    this.keywordsValidationInterpolationParams = {
      field: this.translateService.translate('common_keywords').toLowerCase(),
      maximum: '120'
    };
  }

  ngOnInit() {

    this.form = this.fb.group({
      name: ['', [
          Validators.required,
          Validators.maxLength(40),
          Validators.pattern(NameValidationPattern.pattern)
        ]
      ],
      title: ['', [Validators.required, Validators.maxLength(80)]],
      subtitle: ['', [Validators.maxLength(120)]],
      app: ['', [Validators.required]],
      linkedPageTemplate: [''],
      keywords: ['', [Validators.maxLength(120)]],
      customData: [{}, [validateJson]],
      active: [true, []]
    });

    this.activeRoute.params.subscribe((params: {id: string}) => {
      forkJoin([
        this.pageService.getPage(params['id']),
        this.pageService.getLayouts(),
        this.appService.getAllApps(),
        this.pageService.getPageTemplates({pageSize: 100000})
      ]).subscribe(([
                      pageData,
                      layoutsData,
                      appsData,
                      pageTemplates
                    ]) => {
        this.linkedPageTemplate = pageData.pages.linkedPageTemplate;
        this.pageTemplates = pageTemplates.pageTemplates;
        this.layoutsData = layoutsData.layouts;
        this.form.addControl(
            'layout', this.buildLayouts(
            (pageData.pages.layouts.length && pageData.pages.layouts) || (this.linkedPageTemplate
                && this.linkedPageTemplate.layouts))
        );
        this.form.controls.layout.setValidators([validateCheckboxRequired]);
        this.apps = appsData.apps;
        this.form.patchValue(pageData.pages);

        this.customDataFieldChanged = !isEqual(
            this.form.get('customData').value,
            this.linkedPageTemplate && this.linkedPageTemplate.customData
        );

        this.form.get('customData').valueChanges.subscribe((data: any) => {
          this.customDataFieldChanged = !isEqual(data, this.linkedPageTemplate.customData);
        });

        this.layoutFieldChanged = !isEqual(
            this.getPageTemplateSelectedLayouts(
                this.linkedPageTemplate && this.linkedPageTemplate.layouts
            ),
            this.form.get('layout').value
        );
        this.form.get('layout').valueChanges.subscribe((value: any) => {
          this.layoutFieldChanged = !isEqual(
              this.getPageTemplateSelectedLayouts(this.linkedPageTemplate.layouts),
              value
          );
        });

      });
    });
  }

  onSubmit(form: FormGroup) {
    const updatedPage = cloneDeep(form.value),
        selectedLayouts  = form.value.layout.map((val, index) => {
          return {
            id: this.layoutsData[index].id,
            selected: val
          };
        }).filter((val) => {
          return val.selected;
        }),
        selectedPageTemplate = this.form.controls.linkedPageTemplate.value;

    if (selectedPageTemplate) {
        updatedPage.layouts = selectedLayouts;
    }

    const layoutSelected = updatedPage.layout.find(layout => {
        return !!layout;
    });

    updatedPage.layouts = selectedLayouts;
    updatedPage.linkedPageTemplate = !layoutSelected ?
        null : (form.value.linkedPageTemplate && (form.value.linkedPageTemplate.id || form.value.linkedPageTemplate));
    updatedPage.app = form.value.app && (form.value.app.id || form.value.app);

    // needed only local to build checkboxes
    delete updatedPage.layout;

    this.pageService.updatePage(
        updatedPage,
        this.activeRoute.snapshot.params['id']
    ).subscribe(
        (response) => {
          this.notifyService.notify({
            type: 'pages',
            data: response.pages
          });
          this.notificationsService.show({
            type: 'action',
            content: this.translateService.translate('pages_page_edited')
          });
          this.closePopup();
        },
        (error) => {
          this.notificationsService.show({
            type: 'error',
            content: error.message
          });
        }
    );
  }

  buildLayouts(activeLayouts) {
    const arr = this.layoutsData.map(layout => {
      const found = filter(activeLayouts, function (el, index) {
        return el.id === layout.id;
      });
      return this.fb.control(found.length);
    });

    return this.fb.array(arr);
  }

  get layouts(): FormArray {
    return this.form.get('layout') as FormArray;
  };

  selectPageTemplate(e: CmsPageTemplate) {
    // on select page we must override some fields from chosen page template
    if (!e) {
      return;
    }

    this.pageService.getPageTemplate(e.id).subscribe(res => {
      const titleControl = this.form.controls.title,
          pageTemplate = res.pageTemplate;

      this.linkedPageTemplate = res.pageTemplate;
      const oldLinkedPageTemplate = cloneDeep(this.linkedPageTemplate);

      this.layoutFieldChanged = !isEqual(
          this.getPageTemplateSelectedLayouts(this.linkedPageTemplate.layouts),
          this.form.get('layout').value
      );

      this.customDataFieldChanged = !isEqual(
          this.form.get('customData').value,
          this.linkedPageTemplate.customData
      );

      for (const key in this.form.controls) {

        if (this.form.controls.hasOwnProperty(key)) {
            // if formControl is on list of override fields we will try to override value
            const foundKey = FIELDS_OVERRIDE_WHITELIST.find(field => {
                return field.controlKey === key;
            });

            if (foundKey) {
                const control = this.form.controls[foundKey.controlKey],
                    oldValue = oldLinkedPageTemplate.hasOwnProperty([foundKey['serverKey'] || foundKey.controlKey]) ?
                        oldLinkedPageTemplate[foundKey['serverKey'] || foundKey.controlKey] : false;

                // if property in page template exist and current page controls
                // is not updated by user
                if (oldValue !== false && control.pristine) {
                    // now compare current value with old linked page value
                    // and if they are some override it with new value
                    if (key === 'layout' &&
                        isEqual(
                            this.getPageTemplateSelectedLayouts(oldValue),
                            control.value
                        )) {

                        // now selected layouts extend from chosen page template
                        control.patchValue(
                            this.getPageTemplateSelectedLayouts(pageTemplate.layouts)
                        );
                    } else if (key === 'customData'
                        && (control.value === '' || isEqual(oldValue, control.value))) {
                        control.patchValue(pageTemplate[key]);
                    } else if (oldValue === control.value || control.value === '') {
                        control.patchValue(pageTemplate[key]);
                    }
                }
            }
        }
      }

      // if page template is chosen title is not required field,
      // only set max length validator
      titleControl.setValidators([this.titleValidators[1]]);
      titleControl.updateValueAndValidity();
    });

  }

  getPageTemplateSelectedLayouts(pageTemplateLayouts) {
    const newState = [];
    // now select layouts extend from chosen page template
    this.form.controls.layout.value.forEach((val, index) => {
      const currentLayout = this.layoutsData[index];

      const found = filter(pageTemplateLayouts, function (el) {
        return el.id === currentLayout.id;
      });

      return found.length ? newState.push(1) : newState.push(0);
    });

    return newState;
  }

  closePopup() {
    this.router.navigate([{outlets: {popup: null}}], {queryParamsHandling: 'preserve'});
  }

}
