
import {throttleTime, debounceTime} from 'rxjs/operators';
import {Component, HostBinding, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CmsAppTemplatesService } from '../shared/app-templates.service';
import { CmsCommonService, NameValidationPattern } from '../../shared/common.service';
import { CmsNotifyService } from '../../core/notify.service';
import {clone, cloneDeep, isEqual} from 'lodash';
import {Subject} from 'rxjs';

import {CmsNotificationsService} from '../../core/notifications.service';
import {CmsTranslateService} from '../../shared/translate.service';

@Component({
    selector: 'cms-edit-app-template',
    templateUrl: 'edit-app-template.component.html'
})

export class CmsEditAppTemplateComponent implements OnInit {
    @HostBinding('class') classes = 'popup-overlay';
    appTemplate: FormGroup;
    companies: Array<Object>;
    appTemplates: Array<Object> = [];
    $submit = new Subject<Event>();
    fieldsChanged: boolean = false;
    initialValues;
    nameValidationInterpolationParams;
    titleValidationInterpolationParams;
    validationInterpolation: { nameTemplate: { field: string; maximum: string } };

    constructor(
        private fb: FormBuilder,
        private appTemplatesService: CmsAppTemplatesService,
        private commonService: CmsCommonService,
        private activeRoute: ActivatedRoute,
        private router: Router,
        private notificationsService: CmsNotificationsService,
        private notifyService: CmsNotifyService,
        private translateService: CmsTranslateService
    ) {
        this.setSubmitListener();
        this.nameValidationInterpolationParams = {
            field: this.translateService.translate('common_name').toLowerCase(),
            maximum: '80',
            characters: NameValidationPattern.description
        };
        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '80'
        };
        this.validationInterpolation = {
            nameTemplate: {
                field: this.translateService.translate('application_templates_name_template').toLowerCase(),
                maximum: '80'
            }
        };
    }

    ngOnInit() {
        this.appTemplate = this.fb.group({
            name: ['', [Validators.required, Validators.maxLength(80), Validators.pattern(NameValidationPattern.pattern)]],
            title: ['', [Validators.required, Validators.maxLength(80)]],
            nameTemplate: [{value: '', disabled: true}]
        });

        this.activeRoute.params.subscribe((params: {id: number}) => {
            this.appTemplatesService.getAppTemplate(params['id']).subscribe((appTemplateData) => {


                this.appTemplate.patchValue({
                    name: appTemplateData.appTemplate.name,
                    title: appTemplateData.appTemplate.title,
                    nameTemplate: appTemplateData.appTemplate.nameTemplate
                });

                this.initialValues = clone(this.appTemplate.value);
            });
        });

        this.appTemplate.valueChanges.pipe(
            debounceTime(200))
            .subscribe((app: any) => {
                this.fieldsChanged = !isEqual(app, this.initialValues);
            });
    }

    onSubmit({value}) {
        this.appTemplatesService.editAppTemplate(
            cloneDeep(value),
            this.activeRoute.snapshot.params['id']
        ).subscribe(() => {
            this.notificationsService.show({
                type: 'action',
                content: this.translateService.translate('applications_application_template_edited')
            });
            this.closePopup().then( () => {
                this.notifyService.notify('appTemplate');
            });
        }, (error) => {
            this.notificationsService.show({
                type: 'error',
                content: error
            });
        });
    }

    closePopup() {
        return this.router.navigate([{outlets: {popup: null}}], {queryParamsHandling: 'preserve'});
    }

    private setSubmitListener() {
        this.$submit.pipe(
            throttleTime(500))
            .subscribe(() => {
                this.onSubmit(this.appTemplate);
            });
    }
}
