
import {throttleTime} from 'rxjs/operators';
import {Component, HostBinding} from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {Category, CATEGORY_TYPE, CategoryFormModel, CategoryType, CmsArticlesService} from '../shared/articles.service';
import { CmsCommonService, Company, NameValidationPattern } from '../../shared/common.service';
import { CmsNotifyService } from '../../core/notify.service';
import { UserService } from '../../core/user.service';
import { CmsNotificationsService } from '../../core/notifications.service';
import { Subject } from 'rxjs';

import { CmsTranslateService } from '../../shared/translate.service';
import { sortBy } from 'lodash';
import {CmsAppsService, App} from '../../apps/shared/apps.service';

@Component({
    selector: 'cms-new-category',
    templateUrl: 'new-category.component.html'
})

export class CmsNewCategoryComponent {
    @HostBinding('class') classes = 'popup-overlay';
    applications: App[];
    canCreateGlobalCategory: boolean;
    categoryType = CATEGORY_TYPE;
    categoryTypes = [{
        id: CATEGORY_TYPE.company,
        title: this.translateService.translate('common_company')
    }, {
        id: CATEGORY_TYPE.application,
        title: this.translateService.translate('applications_application')
    }];
    newCategory: FormGroup;
    locales: Array<Object>;
    categories: Array<Category>;
    companies: Array<Company>;
    $submit = new Subject<Event>();
    nameValidationInterpolationParams;
    titleValidationInterpolationParams;
    constructor(
        private fb: FormBuilder,
        private appService: CmsAppsService,
        private articlesService: CmsArticlesService,
        private commonService: CmsCommonService,
        private router: Router,
        private notifyService: CmsNotifyService,
        private userService: UserService,
        private notificationsService: CmsNotificationsService,
        private translateService: CmsTranslateService
    ) {
        this.canCreateGlobalCategory = userService.data.modules['articles']['specialPermissions'].canEditGlobalArticles;

        this.nameValidationInterpolationParams = {
            field: this.translateService.translate('common_name').toLowerCase(),
            maximum: '40',
            characters: NameValidationPattern.description
        };

        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '80'
        };

        this.newCategory = this.fb.group({
            type: ['', [Validators.required]],
            company: '',
            application: '',
            name: ['', [Validators.required, Validators.maxLength(40), Validators.pattern(NameValidationPattern.pattern)]],
            title: ['', [Validators.required, Validators.maxLength(80)]],
            parent: null,
            locale: ['', [Validators.required]],
            active: false,
        });

        this.companies = sortBy(this.userService.data.user.companies, 'title');

        this.loadData();

        this.setDropdownListeners();

        this.setSubmitListener();
    }

    onSubmit({value}: {value: CategoryFormModel}) {
        this.articlesService.createCategory(value).subscribe(() => {
            this.notificationsService.show({
                type: 'action',
                content: this.translateService.translate('articles_category_created')
            });
            this.notifyService.notify('category');
            this.closePopup();
        }, (error) => {
            this.notificationsService.show({
                type: 'error',
                content: error
            });
        });
    }

    closePopup() {
        this.router.navigate([{outlets: {popup: null}}], {queryParamsHandling: 'preserve'});
    }

    private setDropdownListeners() {
        this.newCategory.controls['company'].valueChanges.subscribe((company: Company) => {
            if (company !== null) {
                this.loadAllCategories({ company: company && company.id});
            }
        });

        this.newCategory.controls['application'].valueChanges.subscribe((application: App) => {
            if (application !== null) {
                this.loadAllCategories({ app: application && application.id });
            }
        });

        this.newCategory.get('type').valueChanges.subscribe((type: CategoryType) => {
            this.newCategory.controls[type.id].setValidators([Validators.required]);

            switch (type.id) {
                case this.categoryType.application:
                    if (this.applications &&
                        this.applications.length === 1 &&
                        !this.newCategory.get('application').value) {
                        this.newCategory.patchValue({application: this.applications[0]});
                    }
                    this.newCategory.get('company').clearValidators();
                    this.newCategory.patchValue({company: null});
                    break;
                case this.categoryType.company:
                    if (this.companies &&
                        this.companies.length === 1 &&
                        !this.newCategory.get('company').value) {
                        this.newCategory.patchValue({company: this.companies[0]});
                    }
                    this.newCategory.get('application').clearValidators();
                    this.newCategory.patchValue({application: null});
                    break;
            }
        });
    }

    loadData() {
        this.loadLocales();
        this.loadApplications();
    }

    private loadApplications() {
        this.appService.getAllApps().subscribe(applicationsData => {
            const applications = this.canCreateGlobalCategory ?
                applicationsData.apps :
                applicationsData.apps.filter(application => (application.company !== null));
            this.applications = sortBy(applications, 'name');
        })
    }

    private loadAllCategories(params?: any) {
        this.articlesService.getArticleCategories({...params, pageSize: Number.MAX_SAFE_INTEGER}).subscribe((categoriesData: any) => {
            this.categories = sortBy(categoriesData['categories'], 'title');
            this.clearParent(this.categories);
        });
    }

    private loadLocales() {
        this.commonService.getLocales().subscribe((localeData) => {
            this.locales = sortBy(localeData['locales'], 'title');
        });
    }

    private clearParent(categories: any) {
        const selectedParentCategory = this.newCategory.controls['parent'].value;

        if (selectedParentCategory) {
            const selectedInCategories = categories.some((category: Category) => {
                return category.id === selectedParentCategory.id;
            });

            if (!selectedInCategories) {
                this.newCategory.patchValue({parent: null});
            }
        } else {
            this.newCategory.patchValue({parent: null});
        }
    }

    private setSubmitListener() {
        this.$submit.pipe(
            throttleTime(500))
            .subscribe(() => {
                this.onSubmit(this.newCategory);
            });
    }
}
