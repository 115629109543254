
import {throttleTime} from 'rxjs/operators';
import {Component, HostBinding, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { CmsAppsService } from '../shared/apps.service';
import {CmsCommonService, NameValidationPattern} from '../../shared/common.service';
import {CmsNotifyService} from '../../core/notify.service';
import {cloneDeep, sortBy, template} from 'lodash';
import {Subject} from 'rxjs';

import {CmsNotificationsService} from '../../core/notifications.service';
import {CmsTranslateService} from '../../shared/translate.service';

@Component({
    selector: 'cms-new-app',
    templateUrl: 'new-app.component.html'
})

export class CmsNewAppComponent implements OnInit {
    @HostBinding('class') classes = 'popup-overlay';
    newApp: FormGroup;
    company: Array<Object>;
    appTemplate: Array<Object> = [];
    providers: Array<Object>;
    $submit = new Subject<Event>();
    nameValidationInterpolationParams;
    titleValidationInterpolationParams;
    nameTemplate;

    constructor(
        private fb: FormBuilder,
        private appsService: CmsAppsService,
        private commonService: CmsCommonService,
        private router: Router,
        private notificationsService: CmsNotificationsService,
        private notifyService: CmsNotifyService,
        private translateService: CmsTranslateService
    ) {
        this.setSubmitListener();
        this.nameValidationInterpolationParams = {
            field: this.translateService.translate('common_name').toLowerCase(),
            maximum: '80',
            characters: NameValidationPattern.description
        };
        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '80'
        };
    }

    ngOnInit() {
        this.newApp = this.fb.group({
            name: [
                {
                    value: '',
                    disabled: true
                },
                [
                    Validators.required,
                    Validators.maxLength(80),
                    Validators.pattern(NameValidationPattern.pattern)
                ]
            ],
            title: ['', [Validators.required, Validators.maxLength(80)]],
            appTemplate: ['', [Validators.required]],
            company: ['', [Validators.required]],
            active: false
        });

        this.appsService.getAppsTemplates({pageSize: 1000}).subscribe(data => {
            this.appTemplate = sortBy(data.appTemplates , 'title');
        });

        this.commonService.getCompanies({pageSize: 1000}).subscribe(data => {
            this.company = sortBy(data.companies, 'title');
        });

        this.createNameInterpolationSubscribers();
    }

    createNameInterpolationSubscribers() {
        this.newApp.get('appTemplate').valueChanges.subscribe((value: any) => {
            this.nameTemplate = value.nameTemplate;

            if (this.newApp.get('company').value) {
                this.enableName();
            }

            if (this.nameTemplate && this.newApp.get('company').value && !this.newApp.get('name').value) {
                this.setName(this.newApp.get('company').value);
            }
        });

        this.newApp.get('company').valueChanges.subscribe((value: any) => {
            if (this.newApp.get('appTemplate').value) {
                this.enableName();
            }

            if (this.nameTemplate && !this.newApp.get('name').value) {
                this.setName(this.newApp.get('company').value);
            }
        });
    }

    setName(company) {
        this.newApp.patchValue({
            name: template(this.nameTemplate, {interpolate: /{([\s\S]+?)}/g})({
                companyName: company.name,
                companyPlatformId: company.platformId
            })
        });
    }

    enableName() {
        this.newApp.get('name').enable();
    }

    onSubmit({value}) {
        const app = cloneDeep(value);

        app.company = value.company.id;
        app.appTemplate = value.appTemplate.id;

        this.appsService.createApp(app).subscribe(() => {
            this.notificationsService.show({
                type: 'action',
                content: this.translateService.translate('applications_application_created')
            });
            this.closePopup().then(() => {
                this.notifyService.notify('app');
            });
        }, (error) => {
            this.notificationsService.show({
                type: 'error',
                content: error
            });
        });

    }

    closePopup() {
        return this.router.navigate([{outlets: {popup: null}}], {queryParamsHandling: 'preserve'});
    }

    private setSubmitListener() {
        this.$submit.pipe(
            throttleTime(500))
            .subscribe(() => {
                this.onSubmit(this.newApp);
            });
    }
}
