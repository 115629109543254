
import {forkJoin} from 'rxjs';
import {Component, OnInit, OnDestroy} from '@angular/core';
import {CmsModalService} from '../../core/modal.service';
import {FormBuilder, Validators} from '@angular/forms';
import {CmsNotifyService} from '../../core/notify.service';
import {CmsTranslateService} from '../../shared/translate.service';
import {FormGroup} from '@angular/forms';
import {CmsCommonService, Locale} from '../../shared/common.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Resource} from '../../resources/shared/resources.service';
import {CmsResourcePickerModalComponent} from '../../shared/resource-picker/reference-picker-modal/resource-picker-modal.component';
import {find} from 'lodash';
import {CmsMarketingService} from '../shared/marketing.service';
import {CmsNotificationsService} from '../../core/notifications.service';

@Component({
    selector: 'cms-translate-marketing-slot-item',
    templateUrl: './translate-marketing-slot-item.component.html',
    providers: [CmsMarketingService],
})
export class CmsTranslateMarketingSlotItemComponent implements OnInit, OnDestroy {
    form: FormGroup;
    trustedUrl: SafeUrl;
    localeTo: Locale;
    resourcePickerSubscription;
    constructor(private modalService: CmsModalService,
                private fb: FormBuilder,
                private notifyService: CmsNotifyService,
                private translateService: CmsTranslateService,
                private commonService: CmsCommonService,
                private sanitizer: DomSanitizer,
                private marketingService: CmsMarketingService,
                private notificationsService: CmsNotificationsService) {
    }

    ngOnInit() {
        this.form = this.fb.group({
            reference: ['', [Validators.required]]
        });
        this.trustedUrl = this.sanitizer.bypassSecurityTrustStyle('url("' +
            this['data']['item']['row']['resource']['url'] + '")');

        forkJoin([
            this.commonService.getLocales()
        ]).subscribe(([localeData]) => {
            this.localeTo = find(localeData.locales, {'id': this['data']['item']['language']['id']});

            this.marketingService.getMarketingSlotItem(this['data']['item']['row']['id'], this.localeTo.id)
                .subscribe(res => {
                    this.form.get('reference').patchValue(res.marketingSlotItem.resource);
                });
        });

        this.resourcePickerSubscription = this.notifyService.change$.subscribe((item: {type: string, data: Resource}) => {
            if (item.type === 'resource-picker-modal-pick') {
                this.form.get('reference').patchValue(item.data);
            }
        });
    }

    ngOnDestroy() {
        this.resourcePickerSubscription.unsubscribe();
        this.modalService.close('resource-picker-modal');
    }

    translateItem() {
        this.marketingService.translateMarketingItemSlot({
            itemId: this['data']['item']['row']['id'],
            localeId: this.localeTo.id,
            reference: this.form.get('reference').value.id
        }).subscribe(res => {
                this.notificationsService.show({
                    type: 'action',
                    content: this.translateService.translate('resources_resource_translated')
                });
                this.closePopup();
            }, (error) => {
                this.notificationsService.show({
                    type: 'error',
                    content: error
                });
        });
    }

    openResourcePicker() {
        this.modalService.open({
            id: 'resource-picker-modal',
            component: CmsResourcePickerModalComponent,
            data: {
                options: {
                    mimeType: ['image/png', 'image/jpeg']
                }
            }
        });
    }

    public getImgUrl(resource: Resource) {
        if (!resource) {
            return '';
        }
        return resource['thumb-large-url'] || resource.url;
    }

    closePopup() {
        this.modalService.close('translate-marketing-slot-item');
    }
}
