
import {throttleTime, debounceTime} from 'rxjs/operators';
import {Component, HostBinding} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {CategoryResponse, CmsArticlesService} from '../shared/articles.service';
import {CmsNotifyService} from '../../core/notify.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CmsCommonService, Locale, LocalesResponse} from '../../shared/common.service';
import {Subject} from 'rxjs';

import {CmsNotificationsService} from '../../core/notifications.service';
import {clone, isEqual} from 'lodash';
import {CmsTranslateService} from '../../shared/translate.service';

@Component({
    selector: 'cms-edit-category-translation',
    templateUrl: 'edit-category-translation.component.html'
})

export class CmsEditCategoryTranslationComponent {
    @HostBinding('class') classes = 'popup-overlay';
    category: FormGroup;
    translatedCategory: FormGroup;
    fromLanguageTitle: string;
    toLanguageTitle: string;
    $submit = new Subject<Event>();
    fieldsChanged = false;
    initialValues;
    titleValidationInterpolationParams;
    popupTitleInterpolationParams;

    constructor(
        private activeRoute: ActivatedRoute,
        private articlesService: CmsArticlesService,
        private commonService: CmsCommonService,
        private fb: FormBuilder,
        private notificationsService: CmsNotificationsService,
        private notifyService: CmsNotifyService,
        private router: Router,
        private translateService: CmsTranslateService) {

        this.setSubmitListener();

        this.category = this.fb.group({
            title: [{value: '', disabled: true}]
        });

        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '80'
        };

        this.translatedCategory = this.fb.group({
            name: ['', [Validators.required, ]],
            title: ['', [Validators.required, Validators.maxLength(80)]],
            active: false,
            locale: ['', [Validators.required]],
            parent: '',
            company: '',
            app: ''
        });


        this.activeRoute.params.subscribe((params: {
            categoryId: string,
            localeId: string,
            translationLocaleId: string
        }) => {
            this.articlesService.getArticleCategory(params.categoryId, {locale: params.localeId})
                .subscribe((categoryData: CategoryResponse) => {
                    this.category.patchValue({
                        title: categoryData.category.title
                    });

                    this.articlesService
                        .getArticleCategory(params.categoryId, {locale: params.translationLocaleId, extend: 'locale'})
                        .subscribe((translatedCategoryData: CategoryResponse) => {
                            this.translatedCategory.patchValue({
                                name: translatedCategoryData.category.name,
                                title: translatedCategoryData.category.title,
                                active: translatedCategoryData.category.active,
                                locale: translatedCategoryData.category.locale,
                                parent: translatedCategoryData.category.parent,
                                company: translatedCategoryData.category.company,
                                app: translatedCategoryData.category.app
                            });

                            this.initialValues = clone(this.translatedCategory.value);

                        }, () => {
                            this.translatedCategory.patchValue({
                                name: categoryData.category.name,
                                active: categoryData.category.active,
                                locale: {id: Number(params.translationLocaleId)},
                                parent: categoryData.category.parent,
                                company: categoryData.category.company,
                                app: categoryData.category.app
                            });

                            this.initialValues = clone(this.translatedCategory.value);
                        });

                    this.commonService.getLocales().subscribe((localesData: LocalesResponse) => {
                        const fromLanguage = localesData.locales.find((locale: Locale) => {
                            return locale.id.toString() === this.activeRoute.snapshot.params['localeId'];
                        });

                        this.fromLanguageTitle = fromLanguage.title;

                        const toLanguage = localesData.locales.find((locale: Locale) => {
                            return locale.id.toString() === this.activeRoute.snapshot.params['translationLocaleId'];
                        });

                        this.toLanguageTitle = toLanguage.title;
                        this.popupTitleInterpolationParams = {
                            from: fromLanguage.title,
                            to: toLanguage.title
                        };
                    });
                });
        });

        this.translatedCategory.valueChanges.pipe(
            debounceTime(200))
            .subscribe((category: any) => {
                this.fieldsChanged = !isEqual(category, this.initialValues);
            });
    }

    onSubmit({value}) {
        const articleId = this.activeRoute.snapshot.params['categoryId'];
        this.articlesService.editArticleCategory(value, articleId).subscribe(() => {
            this.notificationsService.show({
                type: 'action',
                content: this.translateService.translate('articles_category_translated')
            });
            this.notifyService.notify('category');
            this.closePopup();
        }, (error) => {
            this.notificationsService.show({
                type: 'error',
                content: error
            });
        });
    }

    closePopup() {
        this.router.navigate([{outlets: {popup: null}}], {queryParamsHandling: 'preserve'});
    }

    private setSubmitListener() {
        this.$submit.pipe(
            throttleTime(500))
            .subscribe(() => {
                this.onSubmit(this.translatedCategory);
            });
    }
}
