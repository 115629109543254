
import {Observable, Subject, forkJoin} from 'rxjs';
import {throttleTime, debounceTime} from 'rxjs/operators';
import {Component, HostBinding, OnInit} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {ArticleResponse, CmsArticlesService} from '../shared/articles.service';
import {CmsCommonService, Locale, LocalesResponse} from '../../shared/common.service';
import {Router, ActivatedRoute} from '@angular/router';
import {CmsNotifyService} from '../../core/notify.service';

import {CmsNotificationsService} from '../../core/notifications.service';
import {clone, isEqual} from 'lodash';
import {CmsTranslateService} from '../../shared/translate.service';


@Component({
    selector: 'cms-edit-article-translation',
    templateUrl: 'edit-article-translation.component.html'
})

export class CmsEditArticleTranslationComponent implements OnInit {
    @HostBinding('class') classes = 'popup-overlay';
    $submit = new Subject<Event>();
    article: FormGroup;
    categoryTranslated = true;
    fromLanguageTitle: string;
    toLanguageTitle: string;
    translatedArticle: FormGroup;
    fieldsChanged = false;
    initialValues;
    titleValidationInterpolationParams;
    popupTitleInterpolationParams;
    summaryValidationInterpolationParams;


    constructor(
        private fb: FormBuilder,
        private articlesService: CmsArticlesService,
        private commonService: CmsCommonService,
        private activeRoute: ActivatedRoute,
        private router: Router,
        private notificationsService: CmsNotificationsService,
        private notifyService: CmsNotifyService,
        private translateService: CmsTranslateService

    ) {
        this.setSubmitListener();

        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '120'
        };

        this.summaryValidationInterpolationParams = {
            field: this.translateService.translate('articles_article_summary').toLowerCase(),
            maximum: '400'
        };
    }

    ngOnInit() {
        this.article = this.fb.group({
            title: [{value: '', disabled: true}, [Validators.required]],
            summary: [{value: '', disabled: true}],
            content: [{value: '', disabled: true}]
        });

        this.translatedArticle = this.fb.group({
            name: ['', [Validators.required]],
            title: ['', [Validators.required, Validators.maxLength(120)]],
            category: ['', [Validators.required]],
            provider: ['', [Validators.required]],
            locale: ['', [Validators.required]],
            status: ['', [Validators.required]],
            summary: ['', [Validators.maxLength(400)]],
            content: [''],
            featuredImage: ''
        });

        this.activeRoute.params.subscribe((params: {
            articleId: string,
            localeId: string,
            translationLocaleId: string
        }) => {
            forkJoin([
                this.commonService.getLocales(),
                this.articlesService.getArticle(params['articleId'], {locale: params.localeId}),
            ]).subscribe(([
                localesData,
                articleData
            ]: [LocalesResponse, ArticleResponse]) => {
                this.article.patchValue({
                    title: articleData.article.title,
                    summary: articleData.article.summary || '',
                    content: articleData.article.content || ''
                });


                this.articlesService.getArticleCategory(articleData.article.category.toString(), {locale: params.translationLocaleId})
                    .subscribe(() => {
                    this.articlesService.getArticle(
                        params['articleId'],
                        {locale: params.translationLocaleId}
                    ).subscribe((articleData1) => {
                        this.translatedArticle.patchValue({
                            name: articleData1['article']['name'],
                            title: articleData1['article']['title'],
                            category: articleData1['article']['category'],
                            locale: params.translationLocaleId,
                            provider: articleData1['article']['provider'],
                            summary: articleData1['article']['summary'] || '',
                            content: articleData1['article']['content'] || '',
                            status: articleData1['article']['status'],
                            featuredImage: articleData1['article']['featuredImage']
                        });

                        this.initialValues = clone(this.translatedArticle.value);
                    }, () => {
                        this.translatedArticle.patchValue({
                            name: articleData.article.name,
                            category: articleData.article.category,
                            locale: params.translationLocaleId,
                            provider: articleData.article.provider,
                            status: articleData.article.status,
                            featuredImage: articleData.article.featuredImage
                        });

                        this.initialValues = clone(this.translatedArticle.value);
                    });
                }, () => {
                    this.categoryTranslated = false;
                    this.notificationsService.show({
                        type: 'error',
                        content: this.translateService.translate('articles_category_translation_required_message')
                    });
                });

                const fromLanguage = localesData.locales.find((locale: Locale) => {
                    return locale.id.toString() === this.activeRoute.snapshot.params['localeId'];
                });

                this.fromLanguageTitle = fromLanguage.title;

                const toLanguage = localesData.locales.find((locale: Locale) => {
                    return locale.id.toString() === this.activeRoute.snapshot.params['translationLocaleId'];
                });

                this.toLanguageTitle = toLanguage.title;

                this.popupTitleInterpolationParams = {
                    from: fromLanguage.title,
                    to: toLanguage.title
                };
            });
        });

        this.translatedArticle.valueChanges.pipe(
            debounceTime(200))
            .subscribe((article: any) => {
                this.fieldsChanged = !isEqual(article, this.initialValues);
            });
    }

    onSubmit({value}) {
        const articleId = this.activeRoute.snapshot.params['articleId'];
        this.articlesService.editArticle(value, articleId).subscribe(() => {
            this.notificationsService.show({
                type: 'action',
                content: this.translateService.translate('articles_article_translated')
            });
            this.notifyService.notify('article');
            this.closePopup();
        }, (error) => {
            this.notificationsService.show({
                type: 'error',
                content: error
            });
        });
    }

    closePopup() {
        this.router.navigate([{outlets: {popup: null}}], {queryParamsHandling: 'preserve'});
    }

    private setSubmitListener() {
        this.$submit.pipe(
            throttleTime(500))
            .subscribe(() => {
                this.onSubmit(this.translatedArticle);
            });
    }
}
