import {Component, HostBinding, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormArray, FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {CmsTranslateService} from '../../shared/translate.service';
import {CmsLayout, CmsPagesService, CmsPageTemplate, FIELDS_OVERRIDE_WHITELIST} from '../shared/pages.service';
import {App, CmsAppsService} from '../../apps/shared/apps.service';
import {filter, cloneDeep, isEqual} from 'lodash';
import {CmsNotificationsService} from '../../core/notifications.service';
import {CmsNotifyService} from '../../core/notify.service';
import {validateJson} from '../../shared/json-editor/json-validator.directive';
import {validateCheckboxRequired} from '../../shared/validators.service';
import {NameValidationPattern} from '../../shared/common.service';

@Component({
  selector: 'cms-new-page',
  templateUrl: './new-page.component.html',
  providers: [CmsPagesService]
})
export class CmsNewPageComponent implements OnInit {
    @HostBinding('class') classes = 'popup-overlay';
  form: FormGroup;
  apps: Array<App>;
  layoutsData: Array<CmsLayout> = [];
  pageTemplates: Array<CmsPageTemplate> = [];
  selectedPageTemplate: CmsPageTemplate = null;
  nameValidationInterpolationParams;
  titleValidationInterpolationParams;
  subtitleValidationInterpolationParams;
  keywordsValidationInterpolationParams;
  customDataFieldChanged = false;
  layoutFieldChanged = false;
  private titleValidators = [
    Validators.required, Validators.maxLength(80)
  ];

  constructor(
      private router: Router,
      private fb: FormBuilder,
      private translateService: CmsTranslateService,
      private pageService: CmsPagesService,
      private appService: CmsAppsService,
      private notificationsService: CmsNotificationsService,
      private notifyService: CmsNotifyService
  ) {
    this.nameValidationInterpolationParams = {
      field: this.translateService.translate('common_name').toLowerCase(),
      maximum: '40',
        characters: NameValidationPattern.description
    };

    this.titleValidationInterpolationParams = {
      field: this.translateService.translate('common_title').toLowerCase(),
      maximum: '80'
    };

    this.subtitleValidationInterpolationParams = {
      field: this.translateService.translate('common_subtitle').toLowerCase(),
      maximum: '120'
    };

    this.keywordsValidationInterpolationParams = {
      field: this.translateService.translate('common_keywords').toLowerCase(),
      maximum: '120'
    };

    this.appService.getAllApps().subscribe(
        (data) => {
          if (data.apps && data.apps.length) {
            this.apps = data.apps;
          }
        }
    );

    this.pageService.getPageTemplates({pageSize: 100000, extend: 'layouts'}).subscribe(
        (data) => {
          if (data.pageTemplates && data.pageTemplates.length) {
            this.pageTemplates = data.pageTemplates;
          }
        }
    );
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', [
          Validators.required,
          Validators.maxLength(40),
          Validators.pattern(NameValidationPattern.pattern)
        ]
      ],
      title: ['', this.titleValidators],
      subtitle: ['', [Validators.maxLength(120)]],
      app: ['', [Validators.required]],
      pageTemplate: [null],
      keywords: ['', [Validators.maxLength(120)]],
      customData: [{}, [validateJson]],
      active: [true, []]
    });

    this.pageService.getLayouts().subscribe(
        (data) => {
          this.layoutsData = data.layouts;
          this.form.addControl('layout', this.buildLayouts());
          this.form.controls.layout.setValidators([validateCheckboxRequired]);
          this.form.get('layout').valueChanges.subscribe((value: any) => {
            if (this.selectedPageTemplate) {
              this.layoutFieldChanged = !isEqual(
                  this.getPageTemplateSelectedLayouts(this.selectedPageTemplate.layouts),
                  value
              );
            }
          });
        }
    );

    this.form.get('customData').valueChanges.subscribe((value: any) => {
        this.customDataFieldChanged = !isEqual(
            this.selectedPageTemplate.customData,
            value
        );
    });

  }

  buildLayouts() {
    const arr = this.layoutsData.map(layout => {
      return this.fb.control(false);
    });

    return this.fb.array(arr);
  }

  get layouts(): FormArray {
    return this.form.get('layout') as FormArray;
  };

  selectPageTemplate(e: CmsPageTemplate) {

    if (e) {
        this.pageService.getPageTemplate(e.id).subscribe(res => {
            this.selectedPageTemplate = res.pageTemplate;

            const titleControl = this.form.controls.title,
                pageTemplate = res.pageTemplate;

            for (const key in this.form.controls) {

                if (this.form.controls.hasOwnProperty(key)) {
                    const foundKey = FIELDS_OVERRIDE_WHITELIST.find(field => { return field.controlKey === key; });
                    if (foundKey) {
                        const control = this.form.controls[foundKey.controlKey];
                        // if property in page template exist and current page control
                        // is not updated by user or empty then update control with page template value
                        if (pageTemplate.hasOwnProperty(foundKey['serverKey'] || foundKey.controlKey)
                            && (control.pristine || control.value === '')) {
                            if (key === 'layout') {
                                // now select layouts extend from chosen page template
                                control.patchValue(
                                    this.getPageTemplateSelectedLayouts(pageTemplate.layouts)
                                );
                            } else {
                                control.patchValue(pageTemplate[key]);
                            }
                        } else if (pageTemplate[key] === '' && control.pristine) {
                            // if current chosen page template property is empty and not yet
                            // updated by user then clear form value
                            control.reset();
                        }
                    }
                }
            }

            // if page template is chosen, title is not required field,
            // only set max length validator
            titleControl.setValidators([this.titleValidators[1]]);
            titleControl.updateValueAndValidity();

        });
    }
  }

  getPageTemplateSelectedLayouts(pageTemplateLayouts) {
    const newState = [];
    // now select layouts extend from chosen page template
    this.form.controls.layout.value.forEach((val, index) => {
      const currentLayout = this.layoutsData[index];

      const found = filter(pageTemplateLayouts, function (el) {
        return el.id === currentLayout.id;
      });

      return found.length ? newState.push(true) : newState.push(false);
    });

    return newState;
  }

  onSubmit(form: FormGroup) {
    const newPage = cloneDeep(form.value);
    let selectedLayouts  = form.value.layout.map((val, index) => {
          return {
            id: this.layoutsData[index].id,
            selected: val
          };
        }).filter((val) => {
          return val.selected;
        });

    const selectedPageTemplate = this.form.controls.pageTemplate.value;

    // app id
    newPage.app = form.value.app.id;

    if (this.form.controls.pageTemplate.value) {
      // if they are same send null
      // otherwise send selected layouts
      if (isEqual(form.value.layout, this.getPageTemplateSelectedLayouts(selectedPageTemplate.layouts))) {
        selectedLayouts = null;
      } else {
        newPage.layouts = selectedLayouts;
      }
    }

    newPage.layouts = selectedLayouts;
    newPage.linkedPageTemplate = form.value.pageTemplate && form.value.pageTemplate.id;

    this.pageService.createPage(newPage).subscribe(
        (res) => {
          this.notifyService.notify({
            type: 'pages',
            data: res.pageTemplate
          });
          this.notificationsService.show({
            type: 'action',
            content: this.translateService.translate('pages_created')
          });
          this.closePopup();
        },
        (error) => {
          this.notificationsService.show({
            type: 'error',
            content: error.message
          });
        }
    );

  }

  closePopup() {
    this.router.navigate(
        [{outlets: {popup: null}}],
        {queryParamsHandling: 'merge'}
    );
  }
}
