import { Subject, forkJoin } from 'rxjs';
import { throttleTime, debounceTime } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { CmsAppsService } from '../shared/apps.service';
import { CmsCommonService, NameValidationPattern } from '../../shared/common.service';
import { CmsNotifyService } from '../../core/notify.service';
import { clone, cloneDeep, isEqual, sortBy } from 'lodash';
import { CmsNotificationsService } from '../../core/notifications.service';
import { CmsTranslateService } from '../../shared/translate.service';
var CmsEditAppComponent = /** @class */ (function () {
    function CmsEditAppComponent(fb, appsService, commonService, activeRoute, router, notificationsService, notifyService, translateService) {
        this.fb = fb;
        this.appsService = appsService;
        this.commonService = commonService;
        this.activeRoute = activeRoute;
        this.router = router;
        this.notificationsService = notificationsService;
        this.notifyService = notifyService;
        this.translateService = translateService;
        this.classes = 'popup-overlay';
        this.appTemplates = [];
        this.$submit = new Subject();
        this.fieldsChanged = false;
        this.setSubmitListener();
        this.nameValidationInterpolationParams = {
            field: this.translateService.translate('common_name').toLowerCase(),
            maximum: '80',
            characters: NameValidationPattern.description
        };
        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '80'
        };
    }
    CmsEditAppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.app = this.fb.group({
            name: ['', [Validators.required, Validators.maxLength(80), Validators.pattern(NameValidationPattern.pattern)]],
            title: ['', [Validators.required, Validators.maxLength(80)]],
            appTemplate: ['', [Validators.required]],
            company: ['', [Validators.required]],
            active: false
        });
        this.activeRoute.params.subscribe(function (params) {
            forkJoin([
                _this.appsService.getApp(params['id'], { extend: 'company,appTemplate' }),
                _this.commonService.getCompanies({ pageSize: 1000 }),
                _this.appsService.getAppsTemplates({ pageSize: 1000 })
            ]).subscribe(function (_a) {
                var appData = _a[0], companyData = _a[1], appTemplateData = _a[2];
                _this.companies = sortBy(companyData.companies, 'title');
                _this.appTemplates = sortBy(appTemplateData.appTemplates, 'title');
                var selectedCompany = _this.companies.find(function (locale) {
                    return locale.id === appData.app.company.id;
                });
                var selectedAppTemplate = _this.appTemplates.find(function (provider) {
                    return provider.id === appData.app.appTemplate.id;
                });
                _this.app.patchValue({
                    name: appData.app.name,
                    title: appData.app.title,
                    appTemplate: selectedAppTemplate,
                    company: selectedCompany,
                    active: appData.app.active
                });
                _this.initialValues = clone(_this.app.value);
            });
        });
        this.app.valueChanges.pipe(debounceTime(200))
            .subscribe(function (app) {
            _this.fieldsChanged = !isEqual(app, _this.initialValues);
        });
    };
    CmsEditAppComponent.prototype.onSubmit = function (_a) {
        var _this = this;
        var value = _a.value;
        var app = cloneDeep(value);
        app.company = value.company.id;
        app.appTemplate = value.appTemplate.id;
        this.appsService.editApp(app, this.activeRoute.snapshot.params['id']).subscribe(function () {
            _this.notificationsService.show({
                type: 'action',
                content: _this.translateService.translate('applications_application_edited')
            });
            _this.closePopup().then(function () {
                _this.notifyService.notify('app');
            });
        }, function (error) {
            _this.notificationsService.show({
                type: 'error',
                content: error
            });
        });
    };
    CmsEditAppComponent.prototype.closePopup = function () {
        return this.router.navigate([{ outlets: { popup: null } }], { queryParamsHandling: 'preserve' });
    };
    CmsEditAppComponent.prototype.setSubmitListener = function () {
        var _this = this;
        this.$submit.pipe(throttleTime(500))
            .subscribe(function () {
            _this.onSubmit(_this.app);
        });
    };
    return CmsEditAppComponent;
}());
export { CmsEditAppComponent };
