
import {forkJoin} from 'rxjs';
import {Component, HostBinding, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CmsLayout, CmsPagesService} from '../shared/pages.service';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CmsTranslateService} from '../../shared/translate.service';
import {AppTemplate, CmsAppsService} from '../../apps/shared/apps.service';
import {cloneDeep, filter} from 'lodash';
import {CmsNotifyService} from '../../core/notify.service';
import {CmsNotificationsService} from '../../core/notifications.service';
import {validateJson} from '../../shared/json-editor/json-validator.directive';
import {NameValidationPattern} from '../../shared/common.service';

@Component({
  selector: 'cms-edit-page-template',
  templateUrl: './edit-page-template.component.html',
  providers: [CmsPagesService]
})
export class CmsEditPageTemplateComponent implements OnInit {
    @HostBinding('class') classes = 'popup-overlay';
  pageTemplate: FormGroup;
  layoutsData: Array<CmsLayout> = [];
  appTemplates: Array<AppTemplate> = [];
  nameValidationInterpolationParams;
  titleValidationInterpolationParams;
  subtitleValidationInterpolationParams;
  keywordsValidationInterpolationParams;

  constructor(private fb: FormBuilder,
              private activeRoute: ActivatedRoute,
              private router: Router,
              private pageService: CmsPagesService,
              private translateService: CmsTranslateService,
              private appService: CmsAppsService,
              private notifyService: CmsNotifyService,
              private notificationsService: CmsNotificationsService
  ) {
    this.nameValidationInterpolationParams = {
      field: this.translateService.translate('common_name').toLowerCase(),
      maximum: '40',
        characters: NameValidationPattern.description
    };

    this.titleValidationInterpolationParams = {
      field: this.translateService.translate('common_title').toLowerCase(),
      maximum: '80'
    };

    this.subtitleValidationInterpolationParams = {
      field: this.translateService.translate('common_subtitle').toLowerCase(),
      maximum: '120'
    };

    this.keywordsValidationInterpolationParams = {
      field: this.translateService.translate('common_keywords').toLowerCase(),
      maximum: '120'
    };
  }

  ngOnInit() {
    this.pageTemplate = this.fb.group({
      name: ['', [
          Validators.required,
          Validators.maxLength(40),
          Validators.pattern(NameValidationPattern.pattern)
        ]
      ],
      title: ['', [Validators.required, Validators.maxLength(80)]],
      subtitle: ['', [Validators.maxLength(120)]],
      appTemplate: ['', [Validators.required]],
      keywords: ['', [Validators.maxLength(120)]],
      customData: [{}, [validateJson]],
      active: [true, []]
    });

    this.activeRoute.params.subscribe((params: {id: string}) => {
      forkJoin([
        this.pageService.getPageTemplate(params['id']),
        this.pageService.getLayouts(),
        this.appService.getAppsTemplates({pageSize: 100000})
      ]).subscribe(([
          pageTemplateData,
          layoutsData,
          appsTemplatesData
      ]) => {
        this.layoutsData = layoutsData.layouts;
        this.pageTemplate.addControl('layout', this.buildLayouts(pageTemplateData.pageTemplate.layouts));
        this.appTemplates = appsTemplatesData.appTemplates;
        this.pageTemplate.patchValue(pageTemplateData.pageTemplate);
      });
    });
  }

  onSubmit(form: FormGroup) {
    const updatedPageTemplate = cloneDeep(form.value);

    updatedPageTemplate.appTemplate = form.value.appTemplate.id || form.value.appTemplate;
    updatedPageTemplate.layouts = form.value.layout.map((val, index) => {
      return {
        id: this.layoutsData[index].id,
        selected: val
      };
    }).filter((val) => {
      return val.selected;
    });

    delete updatedPageTemplate.layout;

    this.pageService.updatePageTemplate(
        updatedPageTemplate,
        this.activeRoute.snapshot.params['id']
    ).subscribe(
        (response) => {
          this.notificationsService.show({
            type: 'action',
            content: this.translateService.translate('pages_template_edited')
          });
          this.notifyService.notify({
            type: 'page-templates',
            data: response.pageTemplate
          });
          this.closePopup();
        },
        (error) => {
          this.notificationsService.show({
            type: 'error',
            content: error.message
          });
        }
    );
  }

  buildLayouts(activeLayouts) {
    const arr = this.layoutsData.map(layout => {
      const found = filter(activeLayouts, function (el) {
        return el.id === layout.id;
      });
      return this.fb.control(found.length);
    });

    return this.fb.array(arr);
  }

  get layouts(): FormArray {
    return this.pageTemplate.get('layout') as FormArray;
  };

  closePopup() {
    this.router.navigate([{outlets: {popup: null}}], {queryParamsHandling: 'preserve'});
  }

}
